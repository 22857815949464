import { useEffect, useState } from 'react'

function useProductsList(data) {
  const [productsList, setProductsList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('/api/list-products', {
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))

      const { edges } = data.allMarkdownRemark

      const priceList = edges.map(({ node }) => {
        const { frontmatter, fields } = node

        const { stripePrice, ...rest } = frontmatter
        if (!stripePrice) {
          return {
            ...rest,
            fields,
          }
        }
        const currentPrice = result.find((p) => p.id === stripePrice.id)
        const { quantity } = currentPrice?.product.metadata
        return {
          ...rest,
          stripePrice,
          ...(quantity && { sold: parseInt(quantity) !== 1 }),
          fields,
        }
      })
      setProductsList(priceList)
    }
    fetchData()
  }, [])

  return { productsList }
}

export default useProductsList
