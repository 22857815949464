import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { formatPrice } from '../../utils/stripe-handle'
import useProductsList from '../hooks/useProductsList'

const StoreRoll = ({ data }) => {
  const { productsList } = useProductsList(data)

  return (
    <div className="columns is-multiline">
      {productsList.length > 0 &&
        productsList.map((product, index) => (
          <div
            className="is-max-desktop column is-4 is-parent is-vertical"
            key={index}
          >
            <article
              className={`store-list-item tile is-child box notification`}
            >
              <header>
                <Link
                  className="product-view-more"
                  to={product.fields.slug}
                  state={{
                    isSold: product.sold,
                  }}
                >
                  {product.featuredImage ? (
                    <div className="featured-thumbnail">
                      {typeof product.featuredImage === 'string' ? (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: product.featuredImage,
                            alt: `featured image thumbnail for post ${product.name}`,
                          }}
                        />
                      ) : (
                        <PreviewCompatibleImage
                          imageInfo={product.featuredImage}
                        />
                      )}
                    </div>
                  ) : null}
                </Link>
              </header>

              <div>
                <div className="title has-text-black has-background-light is-size-4">
                  {product.name}
                </div>
                <p className="has-text-weight-bold">
                  {product.sold ? (
                    <span className="tag is-sold is-black">Sold out!</span>
                  ) : (
                    product.stripePrice &&
                    formatPrice(product.stripePrice?.unit_amount)
                  )}
                  <br />
                  <br />
                </p>
              </div>
            </article>
          </div>
        ))}
    </div>
  )
}

StoreRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ProductsRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "store-product" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                date
                templateKey
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 360, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                stripePrice {
                  id
                  unit_amount
                  quantity
                  product_id
                  currency
                }
                gallery {
                  image {
                    childImageSharp {
                      fluid(maxWidth: 240, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <StoreRoll data={data} count={count} />}
  />
)
