import React from 'react'
import Layout from '../components/Layout'
import storeRoll from '../components/StoreRoll'

const CancelPage = (props) => {
  console.error(props)
  return <Layout>{storeRoll()}</Layout>
}

export default CancelPage
